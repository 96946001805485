<template>
  <div>
    <div class="terms-mobile">
      <div>
        <b-row class="header-logo mt-2 d-flex mb-2">
          <vuexy-logo />
          <h2 class="brand-text text-primary">BRXMIND</h2>
        </b-row>
      </div>
      <div class="terms-text">
        <h1 class="mb-2">TERMOS E CONDIÇÕES</h1>
        <h2 class="mb-2">
          Termos e condições gerais de uso do
          <strong class="brand-text text-primary">BRXMIND</strong>
        </h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt,
          iure vel sunt placeat voluptas nisi, at unde nihil, sit quibusdam
          recusandae debitis commodi ipsum dolore! At est ipsum porro quasi,
          repellendus cupiditate expedita voluptatibus. Nesciunt optio numquam
          vero sequi non aspernatur. Repudiandae, illum? Ducimus quod unde
          vitae? Nostrum, fugiat a.
        </p>
      </div>
      <div class="terms-text">
        <h2>1. Do objeto</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>2. Da aceitação</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>3. Do acesso dos usuários</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>4. Do cadastro</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>5. Dos serviços e produtos</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>6. Do suporte</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>7. Das responsabilidades</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>8. Dos direitos autorais</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>9. Das sanções</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
      <div class="terms-text">
        <h2>10. Da politica de privacidade</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
          veritatis possimus doloribus quasi, similique non optio consequatur
          corporis maiores doloremque odit quam consectetur ut. Ea, corrupti!
          Commodi, ad dicta ab dolorum nihil vero! Blanditiis porro a alias
          necessitatibus, cumque harum reprehenderit excepturi voluptatum
          assumenda distinctio eos dolore laudantium odit non perspiciatis
          numquam accusantium adipisci. Maiores, voluptas ipsa saepe obcaecati
          fuga ullam corrupti harum veniam eos reprehenderit sequi, ea beatae,
          error hic cupiditate perspiciatis quaerat at nisi animi. At assumenda
          consequuntur nesciunt recusandae dolorem corrupti, qui delectus
          officiis dolore sunt dolores perspiciatis repellendus accusamus
          quaerat iste iusto ipsam illum repudiandae unde. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Fugit expedita aperiam maxime ad
          delectus dolor atque qui ea at, perspiciatis blanditiis molestiae nemo
          iusto earum nisi ab placeat tempore, unde quis saepe accusamus
          accusantium omnis eaque! Nobis natus eos cum maxime saepe odio nisi
          vero nostrum vel praesentium harum minus expedita non dignissimos
          libero tempore, qui quia nihil dolore quasi. Facere dolor veniam neque
          ipsam laudantium? Quos reiciendis doloribus necessitatibus! Provident
          perspiciatis perferendis aliquid nemo exercitationem? Quisquam iure
          cumque quod natus sed rerum ipsum odio similique possimus, cupiditate
          omnis laborum fugiat aut recusandae, corporis laudantium perspiciatis
          inventore explicabo doloribus ratione.
        </p>
      </div>
    </div>
    <div>
      <WelcomeFooter />
    </div>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import WelcomeFooter from "./WelcomeFooter.vue";

export default {
  components: {
    VuexyLogo,
    WelcomeFooter,
  },
};
</script>

<style>
.brand-text {
  font-weight: bold;
}

.terms-text h1,
h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.terms-text p {
  text-align: justify;
}
@media screen and (max-width: 812px) {
  .terms-mobile {
    margin: 2rem 2rem 0 2rem;
  }
  .content-footer_google {
    display: none;
  }
}
</style>
